import * as React from 'react'
import Nav from "../components/nav";
import Footer from "../components/footer";
import coaching1 from "../images/coaching-1.jpg"
import coaching2 from "../images/coaching-2.jpg"
import coaching4 from "../images/coaching-4.jpg"
import coaching5 from "../images/coaching-5.jpg"
import Hero from "../components/hero";
import SocialMedia from "../components/social-media";
import {Helmet} from "react-helmet";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Pagination, Navigation } from "swiper";
import {StaticImage} from "gatsby-plugin-image";
import {useCollapse} from "react-collapsed";

import image1 from "../images/1on1page/image1.jpg"
import image2 from "../images/1on1page/image3.png"


function ImageScroller(){
    return(
        <Swiper className={"pb-12 mySwiper"}
                slidesPerView={1}
                spaceBetween={30}
                slidesPerGroup={1}
                loop={true}
                loopFillGroupWithBlank={true}
                pagination={{
                    clickable: true,
                }}
                navigation={true}
                modules={[Pagination, Navigation]}
                observeParents={true}
                observer={true}

        >

            <SwiperSlide className={"!bg-gray-100"}><div className={"h-128"}><img className={"h-full w-full"} src={coaching1}/></div></SwiperSlide>
            <SwiperSlide className={"!bg-gray-100"}><div className={"h-128"}><img className={"h-full w-full"} src={coaching2}/></div></SwiperSlide>
            <SwiperSlide className={"!bg-gray-100"}><div className={"h-128"}><img className={"h-full w-full"} src={coaching4}/></div></SwiperSlide>
            <SwiperSlide className={"!bg-gray-100"}><div className={"h-128"}><img className={"h-full w-full"} src={coaching5}/></div></SwiperSlide>


        </Swiper>
    )
}

function Collapsible(props) {
    const { getCollapseProps, getToggleProps, isExpanded } = useCollapse()

    return (
        <div className={"py-2 border-t-2 border-accent/50"} {...getToggleProps()}>
            <h2 className={"text-lg font-bold p-1 flex justify-between items-center "} >
                {props.title}
                {isExpanded ?
                    <svg className={"fill-accent w-10 h-10 shrink-0"} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M201.4 137.4c12.5-12.5 32.8-12.5 45.3 0l160 160c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L224 205.3 86.6 342.6c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l160-160z"/></svg>
                    :
                    <svg className={"fill-accent w-10 h-10 shrink-0"} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z"/></svg>
                }

            </h2>
            <section  {...getCollapseProps()}>
                {props.children}
            </section>
        </div>
    )
}

function OneOnOneCoaching(){
    return(
        <div>
            <Helmet>
                <title>Kinetic Impact | One on One Personal Training</title>
            </Helmet>
            <Nav/>
            <Hero href={"https://kineticimpact.janeapp.com/#/1st-timers"} header={"Be confident in your skin and free yourself of your physical limitations"}>
                <div id={"content-start"} className={"flex flex-col justify center max-w-5xl mx-auto lg:px-8 pt-8 text-lg bg-white rounded-xl"}>
                    <div className={"flex lg:flex-nowrap flex-wrap w-fit items-center gap-4 p-4 lg:pt-4 pt-0 pb-8"}>
                        <img className={"lg:w-1/2 w-full h-full rounded-2xl"} src={image2}/>
                        <img className={"lg:w-1/2 w-full h-full rounded-2xl"} src={image1}/>

                    </div>
                    <p className={"lg:pt-4 pb-4 max-w-2xl px-4 mx-auto"}>Stop saying no to fun activities because your body can’t handle it. Become stronger and more fit while building confidence in what your body can do. No more standing on the sidelines watching others do fun things. We are here to teach you how to build a healthier version of yourself with 1-on1 personal training sessions. </p>

                    <a className={"mx-auto mb-4"} href={"https://kineticimpact.janeapp.com/#/1st-timers"}><button className={"rounded bg-accent hover:brightness-75 px-8 py-4 text-xl text-center mt-10"}>Let's Get Started!</button></a>



                    <Collapsible title={"I don’t know where to start"}>
                        <div className={"flex flex-col justify-center"}>
                        <p className={"lg:pt-4 pb-4  px-4 mx-auto"}>Are you overwhelmed with all of the information out there so you never stick to a program long enough to see the results? Our 1-on-1 coaching sessions provide the opportunity to build an alliance WITH you so we can guide you to see long term results. </p>
                        <a className={"mx-auto"} href={"https://kineticimpact.janeapp.com/#/1st-timers"}><button className={"rounded bg-accent hover:brightness-75 px-8 py-4 text-xl text-center mt-10"}>Let's Work Together!</button></a>
                        </div>
                    </Collapsible>

                    <Collapsible title={"Do you have trouble staying on track long enough to see results?"}>
                        <div className={"flex flex-col justify-center"}>
                            <p className={"lg:pt-4 pb-4  px-4 mx-auto"}>No more letting one bad day take you for a talespin. You deserve to have someone in your corner to hold you accountable to the goals you have set for yourself. We will be there every step of the way to support and guide you when obstacles arise. We will help you build a resilient mindset. A strong mindset is the foundation of a strong body. Consistency over time is better than high intensity for a short duration of time for achieving long term goals. Just show up and we got your back.</p>
                            <a className={"mx-auto"} href={"https://kineticimpact.janeapp.com/#/1st-timers"}><button className={"rounded bg-accent hover:brightness-75 px-8 py-4 text-xl text-center mt-10"}>Book Initial Exam</button></a>
                        </div>
                    </Collapsible>

                    <Collapsible title={"Tired of doing the same exercises over and over again? Want to learn something new?"}>
                        <div className={"flex flex-col justify-center"}>
                            <p className={"lg:pt-4 pb-4  px-4 mx-auto"}>Do you have a garage full of equipment that you don’t know how to use? It can be overwhelming trying to figure out how to progress in the gym. Most people do the same old exercises and they either become stale or the outcomes don’t match the output. We want to help! Our goal is to educate you so you can take what we teach you and apply it. So you can get the most out of your workouts and live a healthier life.</p>
                            <a className={"mx-auto"} href={"https://kineticimpact.janeapp.com/#/1st-timers"}><button className={"rounded bg-accent hover:brightness-75 px-8 py-4 text-xl text-center mt-10"}>Book Initial Exam</button></a>
                        </div>
                    </Collapsible>

                    <Collapsible title={"Being healthy is my main priority"}>
                        <div className={"flex flex-col justify-center"}>
                            <p className={"lg:pt-4 pb-4 px-4 mx-auto"}>Don’t end up like so many of your family and friends who did not make the investment in their health. If you want to be productive and be able to play with your future grandkids, the work starts now. We prioritize durability in our programming so you can stay young for as long as possible!</p>
                            <a className={"mx-auto"} href={"https://kineticimpact.janeapp.com/#/1st-timers"}><button className={"rounded bg-accent hover:brightness-75 px-8 py-4 text-xl text-center mt-10"}>Time to Stay Young</button></a>
                        </div>
                    </Collapsible>

                    <Collapsible title={"Why do I always get hurt when I exercise?"}>
                        <div className={"flex flex-col justify-center"}>
                            <p className={"lg:pt-4 pb-4 px-4 mx-auto"}>Have you been injured when working out in the past? Do you find it hard to keep up with demands of random cookie cutter programs that don't take into account your individual history, experience, and fitness level? We are here for you. But don’t take it from us, here is a text with one of our clients!</p>

                            <a className={"mx-auto pb-10"} href={"https://kineticimpact.janeapp.com/#/1st-timers"}><button className={"rounded bg-accent hover:brightness-75 px-8 py-4 text-xl text-center mt-10"}>I am ready, let's book!</button></a>
                        </div>
                    </Collapsible>


                    <div className={"hidden lg:block pt-12"}>
                        <ImageScroller />
                    </div>

                </div>
            </Hero>
            <div className={"block lg:hidden pt-12"}>
                <ImageScroller />
            </div>

            



            <Footer/>
        </div>
    )
}

export default OneOnOneCoaching